import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import { Formik, Form, useFormik, Field } from "formik";
import * as Yup from "yup";

import './wwercontact.css'

const WwerContact = (prop) => {


  const initialValues = {
    name: "",
    company: "",
    phone: "",
    email: "",
    comment: "",
}

  const [hide, setHide] = useState(false);
  const [response, setResponse] = useState();
//   const [modalShow, setModalShow] = useState();


  const subscribeSchema = Yup.object({
      name: Yup.string().min(2).max(50).required("Please enter your name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      company: Yup.string().min(2).max(50).matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      email: Yup.string().email().min(2).required("Please enter valid email address").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
      comment: Yup.string().min(4),
      phone: Yup.string()
  .matches(/^([ +0-9]{1,4}|[ -0-9 ]{1,3})?[ 0-9 ]{10,14}$/, "Phone number is not valid ").min(10).max(16)
  .matches(/^[0-9'+/-\s]+$/, "Only Numbers are allowed for this field "),
   });
    

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
      useFormik({
          initialValues,
          validationSchema: subscribeSchema,
          onSubmit: (values, action) => {


            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("company", values.company);
            formData.append("email", values.email);
            formData.append("phone", values.phone);
            formData.append("comment", values.comment);
          
                  setHide(true);
              fetch('https://blogs.conceptvines.com/wp-json/mail/contactform', {
                      method: 'POST',
                      // mode: 'no-cors',
                      // headers: {
                      // 'Content-Type': 'application/json',
                      // },
                      
                      body:formData,
                  })
              .then(res => res.json())
              .then(data => {
              // enter you logic when the fetch is successful
                setHide(false);
                // console.log(data)
                setResponse(data.message);
            // setModalShow(true);
                setTimeout(()=>{
                  setResponse("");
                }, 10000);
      
              })
          
              .catch(error => {
                  // enter your logic for when there is an error (ex. error toast)
                  console.log(error.message);
              }) 
          
              action.resetForm();
             
            
          },
      });

  return (
    <section className={`section-WwerContact ${prop.class}`} id="contact">
        <div className='container'>
            <div className='bl-green py-5'>
                <h1>Let's seize tomorrow, <span>together!</span></h1>
                <p className='text-box'>Our team of industry veterans have spearheaded modernization for Fortune 500 companies. With our AI-first digital engineering powerhouse, we deliver at a breakneck speed at a fraction of the cost.</p>
            </div>

            <Row>
           
                <div className='col-md-12'>
                    <div className='white-bg'>
                    <Formik>
                                    {() => (
                                        <Form onSubmit={handleSubmit}>
                                          <Row className='mb-4'>
                                          <div className='col-md-6'>
                                                <label htmlFor="name" className="">Name <span>*</span></label>
                                                <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                <div className='errormsg'> {errors.name && touched.name ? (
                                                    <p className="form-error">{errors.name}</p>
                                                ) : null}</div>
                                            </div>

                                            <div className='col-md-6'>
                                                <label htmlFor="email" className="">Company Name</label>
                                                <Field type="text" name="company" placeholder='' aria-labelledby="company" value={values.company}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="email" component="div" /> */}
                                                <div className='errormsg'> {errors.company && touched.company ? (
                                                <p className="form-error">{errors.company}</p>
                                            ) : null}</div>
                                            </div>
                                        
                                          </Row>
                                          <Row className='mb-4'>
                                          <div className='col-md-6'>
                                                <label htmlFor="name" className="">Phone Number</label>
                                                <Field type="text" name="phone" placeholder='' aria-labelledby="phone" value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                <div className='errormsg'> {errors.phone && touched.phone ? (
                                                    <p className="form-error">{errors.phone}</p>
                                                ) : null}</div>
                                            </div>

                                            <div className='col-md-6'>
                                                <label htmlFor="email" className="">Email <span>*</span></label>
                                                <Field type="text" name="email" placeholder='' aria-labelledby="email" value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="email" component="div" /> */}
                                                <div className='errormsg'> {errors.email && touched.email ? (
                                                <p className="form-error">{errors.email}</p>
                                            ) : null}</div>
                                            </div>

                                           
                                        
                                          </Row>
                                        
                                          <Row className='mb-5'>

                                            <div className='col-md-12'>
                                                <label htmlFor="email" className="">How can we help you?</label>
                                                <Field type="text" name="comment" placeholder='' aria-labelledby="comment" value={values.comment}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="email" component="div" /> */}
                                                <div className='errormsg'> {errors.comment && touched.comment ? (
                                                <p className="form-error">{errors.comment}</p>
                                            ) : null}</div>
                                            </div>
                                          
  
                                          </Row> 

                                            <div className='text-center'>
                                                {(hide) ? (
                                                        <button 
                                                        disabled
                                                        style={{opacity:"0.5"}}
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </button>
                                                    
                                                    ) :  (
                                                        <button type="submit">Submit</button>                       
                                                        )
                                                    }
                                            </div>

                                            <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>  
                                        
                                        </Form>
                                    )}
                                </Formik>
                            
                    </div>
                </div>

            </Row>
       </div>
    </section> 
  )
}

export default WwerContact
