 import React from 'react'
 import { Helmet } from 'react-helmet';
 import Menu from '../components/Menu';
 import Header2 from '../components/Header2';
 import Footer from '../components/Footer';
import InsightsBody from '../components/InsightsBody';
import WwerContact from '../components/WwerContact';
 
import bgvideo from "../video/Insights_HD.mp4";
import bgvideomb from "../video/mobile/Insights_HD_mb.mp4";

 
 const Insights = () => {

  const heading = `Your Gateway to <br /> <span>Future Readiness</span>`;
  // const btntext = "Unleash data";
  const btntext = "Elevate your future";
  const btnlink = "#contact";
  

   return (
    <>
    <Helmet>
      <title>Insights | ConceptVines</title>
      <meta property="og:title" content="Insights | ConceptVines" />
      <meta name='og:description' content='ConceptVines is a global innovation company focused on business growth through disruptive technologies. Our company has two distinct but tightly integrated purposes. First is our Venture Capital business, ConceptVines Ventures, it is focused on investing in early-stage disruptive technology companies.'/>
      <meta name='keywords' content=''/>
    </Helmet>

    <div>
        <Menu />
        <Header2 class="insights-bg" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} btntext={btntext} btnlink={btnlink} />
        <InsightsBody />
        <WwerContact class="contactbg" />
        <Footer />
    </div>
    </>
   )
 }
 
 export default Insights
 