import React from 'react'
import { HashLink } from 'react-router-hash-link';
import './header2.css'


const Header2 = (props) => {

  return (
    <header className={`header2 ${props.class}`}> 
      {/* poster={bgvideoposter} */}
      {(props.bgvideo === undefined) ? "" : <>
        <video id="background-video" className='desktop' title='bg-video' playsInline  loop={true} muted={true} autoPlay={true} >
            <source src={props.bgvideo} type="video/mp4" />
            {/* <source src={bgvideo} type="video/ogg" /> */}
            Your browser does not support the video tag.
        </video>
      </>}

      {(props.bgvideomb === undefined) ? "" : <>
        <video id="background-video" className='mobile tablet' title='bg-video' playsInline  loop={true} muted={true} autoPlay={true} >
            <source src={props.bgvideomb} type="video/mp4" />
            {/* <source src={bgvideo} type="video/ogg" /> */}
            Your browser does not support the video tag.
        </video>
      </>}
      
        <div className='container'>
            <div className='text-box'>
                <h1  dangerouslySetInnerHTML={{__html: props.heading}}></h1>
                {(props.btntext !== undefined) ?  <HashLink smooth to={props.btnlink} className="main-btn">{props.btntext}</HashLink>  : "" }
            </div>
        </div>
    </header>
  )
}

export default Header2
