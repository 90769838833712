import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../components/Menu'
import Header2 from '../components/Header2'
import AboutSection from '../components/AboutSection'
import CompetitiveEdge from '../components/CompetitiveEdge'
// import HomeReadiness from '../components/HomeReadiness'
import WwerContact from '../components/WwerContact'
import Footer from '../components/Footer'

import aboutimg from '../images/about-ia.webp'

import bgvideo from "../video/IntelAutomation_HD.mp4";
import bgvideomb from "../video/mobile/IntelAutomation_HD_mb.mp4";

const IntelligentAutomation = () => {

    // Header content 
    const heading = `Automation that <br> <span>transforms</span>`;
    // const btntext = "Unlock resilience";
    const btntext = "Talk to us";
    const btnlink = "#contact";
    
    // about section content
    const abouttext = `<p>We pave the path for you to <span>push the boundaries of innovation and efficiency</span> by going beyond just automating tasks to <span>smartly automating decision-making processes</span>. From machine learning and natural language processing to robotic process automation, we <span>integrate AI capabilities to build systems</span> that can learn, adapt, and execute tasks with minimal human intervention. With our proprietary, rapid prototyping platform, we've taken a giant leap towards democratizing intelligent automation.</p>`;

  return (
    <>
     <Helmet>
      <title>Intelligent Automation</title>
      <meta property="og:title" content="Intelligent Automation" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div>
      <Menu />
      <Header2 class="ia-bg" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} btntext={btntext} btnlink={btnlink} />
      <AboutSection img={aboutimg}  text={abouttext} />
      <CompetitiveEdge />
      {/* <HomeReadiness /> */}
      <WwerContact />
      <Footer />      
    </div>
    </>

  )
}

export default IntelligentAutomation
