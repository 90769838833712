import React from 'react'
import { Row } from 'react-bootstrap'
import './fundthesis.css'


import img1 from '../images/Technology-theme.webp'
import img2 from '../images/business-theme.webp'

const FundThesis = () => {
  return (
    <>
    <section className="fundthesis-section">
       <div className='container'>
            <div className='bl-green'>
                   <h2>Fund <span>thesis</span></h2>
                <div className='text-box pe-5'>
                    <p className='desktop'>The core focus of our fund is to invest in 'Big Innovation' - technology and solutions with a potential<br /> to drive transformative market changes</p>
                    <p className='mobile tablet'>The core focus of our fund is to invest in 'Big Innovation' - technology and solutions with a potential to drive transformative market changes</p>
                </div>
            </div>
        </div>


        <div className="container px-0 mx-auto">
        <Row className='align-items-center'>   

                <div className='col-md-6 text-side order-m-1'>
                    <h3>Technology themes</h3>
                    <ul>
                        <li>Artificial Intelligence and Machine Learning (AI/ML), Generative AI (LLM)</li>
                        <li>Blockchain / Distributed Ledger Technologies / DeFi/DAO</li>
                        <li>Internet of Things / Edge Based Computing</li>
                        <li>Digital Twins, Enterprise Gaming and Extended Reality (AR/VR)</li>
                        <li>No/Low Code Application and Process Automation</li>
                    </ul> 
                </div>
                <div className='col-md-6 text-center'>
                    <img src={img1} alt='' />
                </div>
            </Row>

            <Row className='align-items-center'>   
                <div className='col-md-6 text-center'>
                    <img src={img2} alt='' />
                </div>
        
                <div className='col-md-6 text-side'>
                <h3>Business themes</h3>
                        
                        <ul>
                            <li><b>BFSI</b>
                                <p>Digital Banking | Embedded Finance | Digital Assets (DeFi)</p>
                            </li>
                            <li><b>Manufacturing</b>
                                <p>Industry 4.0  |  Smart Factory Automation  |  Digital Twins</p>
                            </li>
                             <li><b>Healthcare</b>
                                <p>Home Healthcare & Monitoring  |  Predictive Care  |  Digital Therapeutics</p>
                            </li>
                            </ul> 
         
                </div>
            </Row>

        </div>

    </section>
    </>
  )
}

export default FundThesis
