import React, {useState, useEffect} from 'react'
import './nextchapter.css'
import { Row } from 'react-bootstrap'
import Position from './Position';

const NextChapter = () => {
      const [activeTab, setActiveTab] = useState("all");
      const [tabs, setTabs] = useState([]);
      const [cat, setCat] = useState('');


        const [posts, setPosts] = useState([]);
        // const [page, setPage] = useState(1);
        // const [total, setTotal] = useState(0);
        
        // // const [postcount, setPostcount] = useState(1);
        
        // const postPerPage = 9;
        
        // // const pagesVisited = page * usersPerPage;
        // const pageCount = Math.ceil(total / postPerPage);
        // const changePage = ({ selected }) => {
        //     // setPageNumber(selected);
        //     // scrollTop();
        //     setPage(selected+1);
        // };
    
    
        useEffect(() => {

            fetch('https://blogs.conceptvines.com/wp-json/position/v1/cat', { method: "GET"})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setTabs(data);
                // let tc = 0;
                // tabs.map((tab)=> {
                //     tc = tc + tab.count;
                // })
                // setTotal(total);
                
            });
    


            fetch('https://blogs.conceptvines.com/wp-json/position/v1/job_posts?cat='+cat, { method: "GET"})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setPosts(data);
                });
    
        }, [cat]);
    

  return (
    <>
    <section className="nextchapter-section">
        <div className="container">
            <div className="bl-green">
                    <h2 className='text-box desktop tablet'>
                    Your Next Chapter: A career at<br/><span>ConceptVines</span>  
                    </h2>

                    <h2 className='text-box mobile'>
                    Your Next Chapter: A career at <span>ConceptVines</span>  
                    </h2>
            </div>

            <Row className='mt-5 pt-0 pt-lg-5'>
                <div className='col-md-3'>
                    <div className='left-side'>
                        <div className="tabs">
                        
                        <div className={activeTab === 'all' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('all'); setCat('');  }} >All positions ({tabs.reduce((total, item) => total + item.count, 0)})</div>                         
                        
                        {tabs.map((tab, index)=> {
                         return (
                            <>
                                <div key={index} className={activeTab === tab.slug ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab(tab.slug); setCat(tab.slug); }} >{tab.name} ({tab.count})</div>                         
                            </>
                         )   
                        })
                    }
                

                    </div>

                        <p className='desktop'>
                            {/* We are always seeking talented people. In case you cannot find your desired position here, please send us your LinkedIn profile and give us your contact information. We will be in touch.  */}
                            We are always seeking talented people. In case you cannot find your desired position here, you can directly email us with your resume at <a style={{display:'inline-block'}} href="mailto:careers@conceptvines.com">careers@conceptvines.com</a> 
                        
                        </p>
                    </div>
                </div>

                <div className='col-md-9'>
                    <div className='right-side'>
                    
                    {posts.map((post, index)=> {
                         return (
                            <>
                            <Position key={index} ID={post.job_id} post_title={post.post_title} 
                            experience={post.experience} location={post.location} 
                            short_description={post.short_description} 
                            post_content={post.post_content} />
                            
                            </>
                         )   
                        })
                    }
                    
                
                   </div> 
                </div>

            </Row>
        </div>
    </section>
    </>
  )
}

export default NextChapter
