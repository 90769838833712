import React from 'react'
import { Row } from 'react-bootstrap'
import './datadriven.css'
import { HashLink } from 'react-router-hash-link'

const Datadriven = () => {
    
  return (
    <>
    <section className="datadriven-section">
        <div className="container">
            <div className="main-box">
                    <h1>
                    Pillars of <span>data-driven</span> success 
                    </h1>
            </div>
        </div>

        <div className="container-fluid px-0 mx-0 desktop" >
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                        <div className="bg-1"></div>
                    {/* <img src={img1} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5 ps--5">
                        <h3>Data Cloud Migration</h3>
                        <h4>Transition to Platforms like Databricks, BigQuery, and More</h4>
                        
                        <p>We drive cloud data warehouse migration for businesses seamlessly and efficiently to ensure they drive the most value from data analytics. </p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Tailored migration plan to ensure a smooth transition</li>
                            <li>Latest ETL (Extract, Transform, Load) tools for efficient data migration and integration</li>
                            <li>Post-Migration Support and Optimization to maximize the benefits of the new data warehouse</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                           <p className="inline-list">Automated Data Migration Tools | Real-Time Data Processing | Integration of AI and Machine Learning | Enhanced Data Security and Compliance</p>                  
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                <div className="text-side px-5 ms-5">
                        <h3>Data Modernization</h3>
                        <h4>Stay Ahead, Data-Driven</h4>
                        
                        <p>We work closely with our clients to align their data strategy with business objectives and ensure that data is effectively used to drive decision-making and innovation.</p>

                        <p>What sets us apart:</p>
                        
                        <ul>
                            <li>Holistic data systems analysis to evaluate the entire data landscape  </li>
                            <li>Customized integration plans to ensure a smooth transition with least disruption</li>
                            <li>Robust data governance and compliance mechanisms to ensure data integrity and regulatory adherence</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Data as a service | Cloud computing | Edge computing | AI & Machine Learning-led solutions | Data fabric & data mesh | Quantum computing | Augmented data management | Real-time data processing</p>                  
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="bg-2"></div>
                      {/* <img src={img2} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-3"></div>
                      {/* <img src={img3} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5 ps--5">
                        <h3>AI / ML Engineering</h3>
                        <h4>Redefine the business paradigm</h4>

                        <p>We embed AI, ML, and generative AI at the heart of our processes, providing our clients with a distinct competitive advantage.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Tailored AI/ML Implementation aligned with custom business goals </li>
                            <li>Generative AI for Creative and Analytical Tasks</li>
                            <li>Ongoing learning and development to ensure that the AI systems evolve with the business</li>                          
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Advancements in Natural Language Processing | Predictive Analytics in Business Decision Making | Automated Machine Learning | Ethical AI and Responsible Usage</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                    <div className="text-side px-5 ms-5">
                        <h3>Generative AI</h3>
                        <h4>Achieve data dominance</h4>

                        <p>From ensuring a robust data infrastructure and data management system to identifying and incorporating the right AI models, we enable businesses to harness the power of Generative AI to make data-backed decisions.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>AI-powered implementation lab to reduce the time to market and enhance productivity </li>
                            <li>Continuously evolving and learning LLMs</li>
                            <li>AI-first trend trackers to identify white spaces</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Automated data analysis | Predictive analytics | Natural Language Processing (NLP) in Data Queries | Real-Time Data Processing and Analytics | Ethical AI and Bias Reduction | AI in IoT Data Analysis</p>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                      <div className="bg-4"></div>
                      {/* <img src={img4} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-5"></div>
                      {/* <img src={img5} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5 ps--5">
                        <h3>MLOps/LLMops</h3>
                        <h4>Streamline data processes</h4>
        
                        <p>We integrate robust MLOps and LLM Ops solutions to ensure efficient AI model lifecycle management and operationalization. Our approach optimizes the deployment, monitoring, and updating of machine learning models, enhancing overall AI effectiveness.</p>
                        
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Tailored MLOps and LLM Ops strategies to fit specific business needs and objectives</li>    
                            <li>Advanced automation in model deployment to improve accuracy and efficiency</li>    
                            <li>Expert Guidance and Support throughout the AI model lifecycle, from development to deployment and maintenance</li>    
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Explainable AI (XAI) | Hybrid and Multi-Cloud Strategies | Serverless MLOps | ML Model Lifecycle Management | ML Model Governance and Compliance</p>                  
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-12 text-center py-5">
                    <HashLink to="#contact" className="main-btn">Unleash data</HashLink>
                </div>
            </Row>
        </div>

        <div className="container-fluid mt-5 px-0 mx-0 mobile tablet">
            <div className="bg-img bg-1">
               <div className="text-side">
                        <h3>Data Cloud Migration</h3>
                        <h4>Transition to Platforms like Databricks, BigQuery, and More</h4>
                        
                        <p>We drive cloud data warehouse migration for businesses seamlessly and efficiently to ensure they drive the most value from data analytics. </p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Tailored migration plan to ensure a smooth transition</li>
                            <li>Latest ETL (Extract, Transform, Load) tools for efficient data migration and integration</li>
                            <li>Post-Migration Support and Optimization to maximize the benefits of the new data warehouse</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                           <p className="inline-list">Automated Data Migration Tools | Real-Time Data Processing | Integration of AI and Machine Learning | Enhanced Data Security and Compliance</p>                  
                    </div>
                </div>
            <div className="bg-img bg-2">
               <div className="text-side">
                        <h3>Data Modernization</h3>
                        <h4>Stay Ahead, Data-Driven</h4>
                        
                        <p>We work closely with our clients to align their data strategy with business objectives and ensure that data is effectively used to drive decision-making and innovation.</p>

                        <p>What sets us apart:</p>
                        
                        <ul>
                            <li>Holistic data systems analysis to evaluate the entire data landscape  </li>
                            <li>Customized integration plans to ensure a smooth transition with least disruption</li>
                            <li>Robust data governance and compliance mechanisms to ensure data integrity and regulatory adherence</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Data as a service | Cloud computing | Edge computing | AI & Machine Learning-led solutions | Data fabric & data mesh | Quantum computing | Augmented data management | Real-time data processing</p>                  
                    </div>
                </div>

                <div className="bg-img bg-3">
                    <div className="text-side">
                        <h3>AI / ML Engineering</h3>
                        <h4>Redefine the business paradigm</h4>

                        <p>We embed AI, ML, and generative AI at the heart of our processes, providing our clients with a distinct competitive advantage.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Tailored AI/ML Implementation aligned with custom business goals </li>
                            <li>Generative AI for Creative and Analytical Tasks</li>
                            <li>Ongoing learning and development to ensure that the AI systems evolve with the business</li>                          
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Advancements in Natural Language Processing | Predictive Analytics in Business Decision Making | Automated Machine Learning | Ethical AI and Responsible Usage</p>
                    </div>
                </div>
           
                <div className="bg-img bg-4">
                    <div className="text-side">
                        <h3>Generative AI</h3>
                        <h4>Achieve data dominance</h4>

                        <p>From ensuring a robust data infrastructure and data management system to identifying and incorporating the right AI models, we enable businesses to harness the power of Generative AI to make data-backed decisions.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>AI-powered implementation lab to reduce the time to market and enhance productivity </li>
                            <li>Continuously evolving and learning LLMs</li>
                            <li>AI-first trend trackers to identify white spaces</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Automated data analysis | Predictive analytics | Natural Language Processing (NLP) in Data Queries | Real-Time Data Processing and Analytics | Ethical AI and Bias Reduction | AI in IoT Data Analysis</p>
                    </div>
                </div>
        
                <div className="bg-img bg-5">           
                    <div className="text-side">
                        <h3>MLOps/LLMops</h3>
                        <h4>Streamline data processes</h4>
        
                        <p>We integrate robust MLOps and LLM Ops solutions to ensure efficient AI model lifecycle management and operationalization. Our approach optimizes the deployment, monitoring, and updating of machine learning models, enhancing overall AI effectiveness.</p>
                        
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Tailored MLOps and LLM Ops strategies to fit specific business needs and objectives</li>    
                            <li>Advanced automation in model deployment to improve accuracy and efficiency</li>    
                            <li>Expert Guidance and Support throughout the AI model lifecycle, from development to deployment and maintenance</li>    
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Explainable AI (XAI) | Hybrid and Multi-Cloud Strategies | Serverless MLOps | ML Model Lifecycle Management | ML Model Governance and Compliance</p>                  
                    </div>
                </div>
            <Row>
                <div className="col-md-12 text-center">
                    <HashLink to="#contact" className="main-btn">Unleash data</HashLink>
                </div>
            </Row>
        </div>
    </section>
    </>
  )
}

export default Datadriven
