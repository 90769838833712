import React, { useState ,useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
// import author from '../images/Rinoza Jiffry.png'

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './singlebody.css'

const SingleBody = () => {

    const [post, setPost] = useState([{ID:0}]);
    const [relatedPost, setRelatedPost] = useState([]);
  // const [content, setContent] = useState("");

  const scrollToTop = () => {
    // setBlank(true);
    setTimeout(() => {
    window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
    // setBlank(false);            
    }, 600);
}



  let { id }  = useParams();  
  
    useEffect(() => {
          fetch('https://blogs.conceptvines.com/wp-json/insights/v1/post?id='+id, { method: "GET"})
          .then(response => response.json())
          .then(data => {
                setPost(data);             
               console.log(data);
            });
  
  
          fetch('https://blogs.conceptvines.com/wp-json/insights/related-post/post?id='+id, { method: "GET"})
          .then(response => response.json())
          .then(data => {
            setRelatedPost(data);
             console.log(data);
          });
    
  
        }, [id]);
  
        function truncateString(string, maxLength) {
            const words = string.split(' ');
            const truncatedWords = words.slice(0, maxLength);
            return truncatedWords.join(' ');
        }

    return (
        <>
            <section className='singlebody-section'>
                <Container>
                    <Row>
                        <Col className='col-md-12'>
                            <div className='header'>
                                <div className='navigation'><NavLink onClick={scrollToTop} to="/">Home</NavLink> <span>{(post.category_name) ? <NavLink onClick={scrollToTop} to="/latest-insights">post.category_name</NavLink> : ""}</span> <span>{(post.post_title) ? truncateString(post.post_title, 2) + "..." : ""}</span></div>

                                <img className='banner-img' src={post.banner_image} alt='' />
                                <div className='blog-heading'>
                                    <h2 className='main-heading'  dangerouslySetInnerHTML={{__html:post.post_title}}></h2>
                                    <div className='d-flex'>
                                        <div className='author-des'>
                                            <span><img src={post.author_avatar} alt='' width="22px" height="22px" /></span>
                                            <span>By {post.author_name}</span> 
                                        </div>    
                                        
                                        <svg width="17" height="2" viewBox="0 0 17 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.681641" y="0.292969" width="15.6543" height="0.782716" fill="white"/>
                                        </svg>

                                        <div className='readtime'>
                                            <span><svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.95003 8.32974C7.12509 8.32974 8.88833 6.5665 8.88833 4.39143C8.88833 2.21637 7.12509 0.453125 4.95003 0.453125C2.77496 0.453125 1.01172 2.21637 1.01172 4.39143C1.01172 6.5665 2.77496 8.32974 4.95003 8.32974Z" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4.95339 4.39107L3.64062 5.26625M4.95339 2.20312V4.39107" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </span>
                                            <span>{post.read_time} minute read</span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* <img className='w-100' src={post.thumbnail} alt='' /> */}
                        </Col>
                        </Row>
                        
                        <Row>
                         <div className='col-md-1'></div>   
                        <div className='col-md-11 blog-text paddingright'>
                            <div className='mt-5 blog-content' dangerouslySetInnerHTML={{__html:post.post_content}}>
                            </div>
                        
                        </div>


                        <div className='col-md-12 mt-5 tab-div  desktop tablet'>
                        
                        <div className='related-post'>
                        <h3 className='rp-heading'>You may also like</h3>
                        
                        {/* {relatedPost.map((relpost) => {
                            var title =  relpost.post_title.substring(0, 20);
                            //var content =  relpost.post_content.substring(0, 200);

                            const htmlStr = relpost.post_content;
                            const newStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
                            const content = newStr.substring(0, 75).replace(/&nbsp;/gi," ").trim()+"..";                                
                            
                            return (

                                <Row key={relpost.ID} className='botm-border g-0'>
                                    <Col className='col-md-2 img-div'>
                                        <img src={relpost.thumbnail} alt=''/>
                                    </Col>
                                    <Col className='col-md-10 paddingleft2'>
                                        <NavLink onClick={scrollToTop} to={`/single/${relpost.ID}`} >
                                            <div>
                                            <h4 dangerouslySetInnerHTML={{__html:title}}></h4>
                                            <p className='side-para' dangerouslySetInnerHTML={{__html:content}}></p>
                                            </div>
                                        </NavLink>
                                    </Col>
                                </Row>

                                );
                            })}    */}


                        <Swiper
                            slidesPerView={3}
                            spaceBetween={20}
                            centeredSlides={false}
                            loop={false}
                            
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            pagination={false}
                            // pagination={{ clickable: true }}
                            navigation={true}
                          //modules={[ Pagination, Navigation]}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="insights"
                          >

                            {relatedPost.map((d) => {
                                const htmlStr = d.short_description;
                                const newStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
                                // const short = newStr.substring(0, 80).replace(/&nbsp;/gi," ");
                                
                                
                                const htmlStr1 = d.post_title;
                                const newStr1 = htmlStr1.replace(/(<([^>]+)>)/ig, '');
                                // const title = newStr1.substring(0, 50).replace(/&nbsp;/gi," ");
                                const title = truncateString(newStr1,10);
                                const short = truncateString(newStr,30);
                                
                              return (
                                <SwiperSlide key={d.index}>
                                <div className='insight' key={d.ID}>
                            <div className='relative'>
                                <NavLink to={`/single/${d.ID}`} >
                                    <img src={d.thumbnail} alt='' />
                                </NavLink> 
                                <div className='over'>
                                    <div className='readtime'>
                                        <span><svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.95003 8.32974C7.12509 8.32974 8.88833 6.5665 8.88833 4.39143C8.88833 2.21637 7.12509 0.453125 4.95003 0.453125C2.77496 0.453125 1.01172 2.21637 1.01172 4.39143C1.01172 6.5665 2.77496 8.32974 4.95003 8.32974Z" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.95339 4.39107L3.64062 5.26625M4.95339 2.20312V4.39107" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span>{d.read_time} minute read</span>
                                    </div>
                                </div>
                            </div>
                        <div>
                        <NavLink to={`/single/${d.ID}`} >
                                
                            <h2 className='mt-4'>{title}</h2>
                                <div className='author-line'>
                                    <div className='author'>
                                        <div className='author-icon'>
                                            <img src={d.author_avatar} alt='' />
                                        </div>
                                        <div className='author-des'>
                                            <div>{d.author_name}</div>
                                            <div>{d.post_date}</div>
                                        </div>
                                    </div>

                              
                                </div>

                                    <p className='mt-4 pb-2'>{short}</p>
                                </NavLink>
                                
                                </div>
                                    <NavLink className="link" to={`/single/${d.ID}`} >View Post</NavLink>    
                        </div>
                              </SwiperSlide>
                              )
                            })
                            }

                        </Swiper>                          

                        </div>  
               
                        </div>


                        <div className='col-md-12 mt-5 tab-div  mobile'>
                        
                        <div className='related-post'>
                        <h3 className='rp-heading'>You may also like</h3>
                        

                        <Swiper
                            slidesPerView={1}
                            spaceBetween={20}
                            centeredSlides={false}
                            loop={false}
                            
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            // pagination={false}
                            pagination={{ clickable: true }}
                            navigation={false}
                          //modules={[ Pagination, Navigation]}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="insights"
                          >

                            {relatedPost.map((d) => {
                                const htmlStr = d.short_description;
                                const newStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
                                // const short = newStr.substring(0, 80).replace(/&nbsp;/gi," ");
                                
                                
                                const htmlStr1 = d.post_title;
                                const newStr1 = htmlStr1.replace(/(<([^>]+)>)/ig, '');
                                // const title = newStr1.substring(0, 50).replace(/&nbsp;/gi," ");
                                const title = truncateString(newStr1,10);
                                const short = truncateString(newStr,30);
                                
                              return (
                                <SwiperSlide key={d.index}>
                                <div className='insight' key={d.ID}>
                            <div className='relative'>
                                    <img src={d.thumbnail} alt='' />
                                <div className='over'>
                                    <div className='readtime'>
                                        <span><svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.95003 8.32974C7.12509 8.32974 8.88833 6.5665 8.88833 4.39143C8.88833 2.21637 7.12509 0.453125 4.95003 0.453125C2.77496 0.453125 1.01172 2.21637 1.01172 4.39143C1.01172 6.5665 2.77496 8.32974 4.95003 8.32974Z" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.95339 4.39107L3.64062 5.26625M4.95339 2.20312V4.39107" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span>{d.read_time} minute read</span>
                                    </div>
                                </div>
                            </div>
                        <div>
                            <h2 className='mt-4'>{title}</h2>
                                <div className='author-line'>
                                    <div className='author'>
                                        <div className='author-icon'>
                                            <img src={d.author_avatar} alt='' width="22" height="22" />
                                        </div>
                                        <div className='author-des'>
                                            <div>{d.author_name}</div>
                                            <div>{d.post_date}</div>
                                        </div>
                                    </div>

                              
                                </div>

                                    <p className='mt-4 pb-2'>{short}</p>
                                </div>
                                    <NavLink className="link" to={`/single/${d.ID}`} >View Post</NavLink>    
                        </div>
                              </SwiperSlide>
                              )
                            })
                            }

                        </Swiper>                          

                        </div>  

                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SingleBody