import React from 'react'
import './aboutsection.css';

const AboutSection = (props) => {
  return (
<>
    <section className={`about-ca-section ${props.class}`}>
        <div className='container'>
            <div className='row align-items-center  g-5'>
                <div className='col-md-8 order-m-1'>
                    <div className='bl-green'>
                        <div className='text-box' dangerouslySetInnerHTML={{__html: props.text}} >
                        </div>
                    </div>
                </div>
                <div className='col-md-4 text-center'>
                    <img src={props.img} alt='' />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutSection
