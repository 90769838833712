import React, { useState, useEffect } from 'react'
import ReactPaginate from "react-paginate";
import { Container,Row,Col } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
import './insightlist.css'


const InsightsList = (props) => {

    const scrollTop = () => {
        setTimeout(() => {
            window.scrollTo(0, window.innerHeight / 2)
            }, 100);
      }

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    
    // const [postcount, setPostcount] = useState(1);
    
    const postPerPage = 9;
    
    // const pagesVisited = page * usersPerPage;
    const pageCount = Math.ceil(total / postPerPage);
    const changePage = ({ selected }) => {
        // setPageNumber(selected);
        scrollTop();
        setPage(selected+1);
    };


    useEffect(() => {

            fetch('https://blogs.conceptvines.com/wp-json/insights/v1/posts?page='+page+'&&cat='+props.type, { method: "GET"})
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setPosts(data);
                setTotal(data[0].total);
            });

    }, [page,props.type]);


  return (
    <>
    <Container className='insight-section'>
        <div className='insights-list'>

            {
                posts.map((d) => {

                    const htmlStr = d.short_description;
                    const newStr = htmlStr.replace(/(<([^>]+)>)/ig, '');
                    // const short = newStr.substring(0, 80).replace(/&nbsp;/gi," ");
                    
                    const htmlStr1 = d.post_title;
                    const newStr1 = htmlStr1.replace(/(<([^>]+)>)/ig, '');
                    // const title = newStr1.substring(0, 50).replace(/&nbsp;/gi," ");
                    const title = newStr1;
                    const short = newStr;
                    
             
                    return (
                     
                     <>
                        <div className='insight' key={d.ID}>
                            <div className='relative'>
                                <NavLink to={`/single/${d.ID}`} >
                                    <img src={d.thumbnail} alt='' />
                                </NavLink>
                                <div className='over'>
                                    <div className='readtime'>
                                        <span><svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.95003 8.32974C7.12509 8.32974 8.88833 6.5665 8.88833 4.39143C8.88833 2.21637 7.12509 0.453125 4.95003 0.453125C2.77496 0.453125 1.01172 2.21637 1.01172 4.39143C1.01172 6.5665 2.77496 8.32974 4.95003 8.32974Z" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4.95339 4.39107L3.64062 5.26625M4.95339 2.20312V4.39107" stroke="white" stroke-opacity="0.81" stroke-width="0.787661" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        <span>{d.read_time} minute read</span>
                                    </div>
                                    {/* <div className='share'>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.70444 6.7458C3.37179 6.7458 3.91278 6.21682 3.91278 5.5643C3.91278 4.91178 3.37179 4.38281 2.70444 4.38281C2.03709 4.38281 1.49609 4.91178 1.49609 5.5643C1.49609 6.21682 2.03709 6.7458 2.70444 6.7458Z" stroke="white" stroke-width="1.18149" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9.14975 3.20283C9.8171 3.20283 10.3581 2.67386 10.3581 2.02134C10.3581 1.36882 9.8171 0.839844 9.14975 0.839844C8.4824 0.839844 7.94141 1.36882 7.94141 2.02134C7.94141 2.67386 8.4824 3.20283 9.14975 3.20283Z" stroke="white" stroke-width="1.18149" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9.14975 10.2927C9.8171 10.2927 10.3581 9.7637 10.3581 9.11118C10.3581 8.45866 9.8171 7.92969 9.14975 7.92969C8.4824 7.92969 7.94141 8.45866 7.94141 9.11118C7.94141 9.7637 8.4824 10.2927 9.14975 10.2927Z" stroke="white" stroke-width="1.18149" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M3.75781 6.14604L8.09627 8.53216M8.09627 2.60156L3.75781 4.98768" stroke="white" stroke-width="1.18149" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div> */}
                                </div>
                            </div>
                        <div>
                            <NavLink  to={`/single/${d.ID}`} >
                            <h2 className='mt-4'>{title}</h2>
                                <div className='author-line'>
                                    <div className='author'>
                                        <div className='author-icon'>
                                            <img src={d.author_avatar} alt='' width="22px" height="22px" />
                                        </div>
                                        <div className='author-des'>
                                            <div>{d.author_name}</div>
                                            <div>{d.post_date}</div>
                                        </div>
                                    </div>

                                    {/* <div className='share'>
                                        <span><svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.68525" cy="5.46845" r="2.36298" fill="#6C757D"/>
                                            <path d="M10.8719 6.5427C11.4786 6.5427 11.9704 6.06181 11.9704 5.46861C11.9704 4.87541 11.4786 4.39453 10.8719 4.39453C10.2652 4.39453 9.77344 4.87541 9.77344 5.46861C9.77344 6.06181 10.2652 6.5427 10.8719 6.5427Z" stroke="#6C757D" stroke-width="0.805562" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.7294 3.32004C17.336 3.32004 17.8278 2.83916 17.8278 2.24596C17.8278 1.65276 17.336 1.17188 16.7294 1.17188C16.1227 1.17188 15.6309 1.65276 15.6309 2.24596C15.6309 2.83916 16.1227 3.32004 16.7294 3.32004Z" stroke="#6C757D" stroke-width="0.805562" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.7294 9.76535C17.336 9.76535 17.8278 9.28447 17.8278 8.69127C17.8278 8.09807 17.336 7.61719 16.7294 7.61719C16.1227 7.61719 15.6309 8.09807 15.6309 8.69127C15.6309 9.28447 16.1227 9.76535 16.7294 9.76535Z" stroke="#6C757D" stroke-width="0.805562" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M11.8281 5.99569L15.7722 8.16488M15.7722 2.77344L11.8281 4.94264" stroke="#6C757D" stroke-width="0.805562" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg></span>
                                        <span>1K shares</span>
                                    </div> */}

                                </div>

                                    <p className='mt-4 pb-2'>{short}</p>
                                            {/* <p className='anima'><span className='anima-underline' > **** </span></p> */}
                                        </NavLink>
                                </div>
                                    <NavLink className="link" to={`/single/${d.ID}`} >View Post</NavLink>    
                        </div>

                     </>   
                    )
                })
            }
        </div>
        

        <Row className='pagination mt-5'>
            <Col className='col-md-6 ms-auto text-right'>
                <ReactPaginate
                // previousLabel={<img src={previousp} alt='' />}
                // nextLabel={<img src={nextp} alt='' />}
                previousLabel="<"
                nextLabel=">"
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                />
            </Col>     
        </Row>
    </Container>
</>
  )
}

export default InsightsList
