import React, { useEffect } from 'react'
import './wwerinnovators.css';
// import { NavLink } from 'react-router-dom';

import profile from '../images/linkedin-profile.svg'
import profilemb from '../images/profile-mb.webp'

import conimg1 from '../images/JIM FRANCIS.webp'
// import conimg7 from '../images/Natarajan Narayanasamy.webp'
import conimg2 from '../images/SENTHIL-RAVINDRAN.webp'
import conimg3 from '../images/AMAL-SUD.webp'
// import conimg4 from '../images/John-Engerholm.webp'
import conimg4 from '../images/john.png'
// import conimg4 from '../images/ANURADHA-WEERAMAN.webp'
// import conimg5 from '../images/ZACK-ZELDIN.webp'
// import conimg6 from '../images/ANGELIQUE-STRAUSS.webp'

import venimg1 from '../images/BRIAN-FEUER.webp'
import venimg2 from '../images/MITCH-DAVIS.webp'
// import venimg3 from '../images/NICOLAS-PERKIN.webp'
// import venimg4 from '../images/BILLY-LEVY.webp'
// import venimg5 from '../images/DREW-EVENSON.webp'
// import venimg6 from '../images/SUMMER-WATSON.webp'
// import venimg7 from '../images/VIKAL-KAPOOR.webp'
import venimg8 from '../images/ELIZABETH-FURBER.webp'

import advimg1 from '../images/RICHARD-FULD.webp'
import advimg2 from '../images/ERIC-MARTINEZ.webp'
import advimg3 from '../images/GREG-BAXTER.webp'
import advimg4 from '../images/ALYSE-DAGHELIAN.webp'
import advimg5 from '../images/KURT-CAVANO.webp'
import advimg6 from '../images/ROBIN-FARMANFARMAIAN.webp'
import advimg7 from '../images/CHRISTOPHER-COLBERT.webp'
import advimg8 from '../images/NADEEM-SHAIKH.webp'
import advimg9 from '../images/VASAN-SRINIVASAN.webp'
import advimg10 from '../images/DAN-NALAWADE.webp'
import MemberBio from './MemberBio';


const WwerInnovators = () => {

    useEffect(() => {


            var close = document.querySelectorAll('.close');
            for(var i=0; i<close.length; i++) {
                close[i].addEventListener('click', function() {
                    var bio = document.querySelectorAll('.bio');
                    for(var j=0; j<bio.length; j++) {
                            bio[j].style.visibility = 'hidden'; 
                    }
                });
            }
            


        var btns = document.querySelectorAll('.showbio');
        for( i=0; i<btns.length; i++) {
            btns[i].addEventListener('click', function(event) {
                event.preventDefault();
                var parentElement = this.closest('.additional');
                if(parentElement) {

                    var bio = document.querySelectorAll('.bio');
                    for(var j=0; j<bio.length; j++) {
                            bio[j].style.visibility = 'hidden'; 
                    }
                    parentElement.children[0].style.visibility = 'visible'; 
                }
            });
        }
            


        // document.querySelector('.showbio').addEventListener('click', function() {

        //     var parentElement = this.closest('.additional');
        //     if(parentElement) {
        //         parentElement.style.backgroundColor = 'red'; // Change the background color to red
        //         // Add more style changes as needed
        //     }
            
        //     // var elements = document.querySelectorAll('.bio');
        //     // for(var i=0; i<elements.length; i++) {
        //     //     elements[i].style.color = 'red'; // Change the color to red
        //     //     // Add more style changes as needed
        //     // }
        // });

    });

    // useEffect(()=>{


        

    //     var modal = document.getElementById("myModal");

    //     // Get the button that opens the modal
    //     var btn = document.getElementById("myBtn");
        
    //     // Get the <span> element that closes the modal
    //     var span = document.getElementsByClassName("close")[0];
        
    //     // When the user clicks on the button, open the modal
    //     btn.onclick = function() {
    //       modal.style.display = "block";
    //     }
        
    //     // When the user clicks on <span> (x), close the modal
    //     span.onclick = function() {
    //       modal.style.display = "none";
    //     }
        
    //     // When the user clicks anywhere outside of the modal, close it
    //     window.onclick = function(event) {
    //       if (event.target == modal) {
    //         modal.style.display = "none";
    //       }
    //     }
        
    // })

    const consulting = [
        {
            img :conimg1,
            name:"JIM FRANCIS",
            post:"CEO & Co-Founder",
            linkedin: "https://www.linkedin.com/in/jim-francis-578381/",
            bio:`    <p>Jim Francis is the CEO and Co-Founder of ConceptVines Ventures (CVV), where he mentors founders, invests in startups, and provides board advisory services. He has an extensive background in technology consulting and engineering, having helped two organizations cross the $1 billion revenue mark and achieve successful exits. Jim has worked across all industries, including Banking & Financial Services, Insurance, Healthcare, and Life Sciences. He is a technologist with experience in various advanced technology areas such as AI/ML, blockchain, IIoT, and data analytics. Jim is a proven business strategist with experience running global business units, sales and marketing teams, alliance/channel partner relationships, and large deal teams. He has a global network of relationships at Fortune 500 accounts.
            <br /><br />
            
            ConceptVines Ventures aims to improve startups' success rates by adding a higher level of active engagement with portfolio companies, providing capital investment, advisory services, and value-added services in product strategy, design, and engineering. Prior to starting ConceptVines, Jim held various leadership roles at Virtusa, a billion-dollar global technology firm with over 30,000 consultants and engineers, where he ran various business units, sales and marketing organizations, alliances, and formed the company's strategic deals group. He was also an executive at American Management Systems (AMS) in the Corporate Banking industry group and gained international experience and relationships as an expat living in London, Frankfurt, and Australia. Jim has an MBA from Binghamton University and a BS in Business Economics from Oneonta College.</p>`
        },
        // {
        //     img :conimg7,
        //     name:"NATARAJAN NARAYANASAMY",
        //     post:"COO, ConceptVines India",
        //     linkedin: "https://www.linkedin.com/in/narayanasamy-natarajan-0a5927/",
        //     bio:""
        // },
        {
            img :conimg2,
            name:"SENTHIL RAVINDRAN",
            post:"Head of Financial Services, Technology",
            linkedin: "https://www.linkedin.com/in/senthil-ravindran/",
            bio:`    <p>Senthil Ravindran is a distinguished senior executive and thought leader in Banking & Financial Services and Web3. He is based in London and has a track record of building and leading high-performance teams to tackle complex challenges and achieve successful business outcomes. As a Partner at ConceptVines, he spearheads the BFS and Web3 business lines, building a high-quality portfolio of business and driving sustainable growth.

            <br /><br />           
            
            Mr. Ravindran has worked with top-tier financial institutions such as J.P. Morgan, Citi, Barclays, CBA, and HSBC. He is an expert in constructing mission-critical platforms using cutting-edge technologies, and building Intellectual Property that addresses critical challenges in payments, lending, transaction banking, digital securities, and private and public capital markets.
            
            <br /><br />           
            
            At Virtusa, Mr. Ravindran established xLabs as a corporate ventures arm that led to significant growth momentum, acquiring over ten patents and winning awards from Microsoft, BIS, and SWIFT. He has also built unique frameworks for the banking industry using cloud-native technologies, AI/ML, blockchain, and advanced cryptographic technologies that became widely adopted by the Monetary Authority of Singapore, World Bank, BNP Paribas, MUFG, ENBD, and others across the globe.
            
            <br /><br />           
            
            Apart from his professional achievements, Senthil has a passion for History, Greek and Eastern Philosophy, and algorithmic models. He is currently authoring his first book, "Unfinance," a business novel about the "Future of Financial Services in the era of Web3 & Mars Colonization.</p>`
        },
        {
            img :conimg3,
            name:"AMAL SUD",
            post:"Head of Insurance & Commercial Sectors",
            linkedin: "https://www.linkedin.com/in/amalsud/",
            bio:`    <p>Amal is a dynamic and innovative leader with extensive global experience in driving business growth. Currently a Partner at ConceptVines, Amal is responsible for leading the Insurance, Manufacturing & Logistics, and Healthcare businesses, as well as driving Industry 4.0 and associated technologies. He has a strong entrepreneurial spirit and a results-driven mindset that enables him to deliver sustainable high-quality growth in a challenging market environment.

            <br /><br />

            Amal excels in leveraging innovation to accelerate new businesses and turn around struggling ones. He is a strategic thinker who can handle complex challenges across all aspects of a business, including finance, operations, human resources, and technology. With a proven track record of motivating management and teams to work together towards a common objective, Amal is a leader for change who can drive initiatives from ideation to implementation.
            
            <br /><br />
            
            In addition to his leadership roles, Amal is an experienced Angel investor and mentor, providing advisory around go-to-market strategies, operations, and technology. He has held senior leadership positions at BNP Paribas Cardif and Virtusa, where he led some of the largest digital transformation initiatives in the insurance industry.
            
            <br /><br />

            Amal holds an MBA degree and frequently shares his insights on a variety of topics, including history, current affairs, and the intersection of history and technology. His multicultural background and extensive international experience have instilled in him a deep appreciation for different cultures. As a highly motivated and confident leader, Amal is committed to driving innovation, delivering results, and building strong partnerships to create sustainable growth for businesses and communities.</p>`
        },
        // {
        //     img :conimg4,
        //     name:"ANURADHA WEERAMAN",
        //     post:"Head of Engineering",
        //     linkedin: "https://www.linkedin.com/in/anuradhaweeraman/",
        //     bio:``
        // },
        // {
        //     img :conimg5,
        //     name:"ZACK ZELDIN",
        //     post:"Web3 and Business Consulting",
        //     linkedin: "https://www.linkedin.com/in/zack-zeldin-3740882b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
        //     bio:``
            
        // },
        // {
        //     img :conimg6,
        //     name:"ANGELIQUE STRAUSS",
        //     post:"Head of Digital Marketing and Experience",
        //     linkedin: "https://www.linkedin.com/in/angeliquestrauss",
        //     bio:``
        // },
        {
            img :conimg4,
            name:"JOHN ENGERHOLM",
            post:"Head of Healthcare & Life Sciences",
            linkedin: "https://www.linkedin.com/in/johnengerholm/",
            bio:`    <p>John Engerholm is a Partner and Head of Healthcare and Life Sciences (HLS) at ConceptVines, where he spearheads the development and oversight of the HLS business unit. With a distinguished career in business transformation, rapid growth and innovative solution creation, John is pivotal in driving the strategic direction and growth of the HLS segment.  He is regarded as an influential leader and advocate for digital modernization, value-added services, cost-effective solutions, nearshore & offshore strategy and execution agility. 
            <br /><br />
            
            John's extensive experience spans over 20 years across Healthcare Payers, Providers, HealthTech, and Life Sciences.  He has been instrumental in establishing partnerships with industry leaders and ensuring the delivery of cutting-edge solutions to clients.   Before joining ConceptVines, John was a Senior Managing Director at Globant, Senior Vice President at Virtusa and Chief Financial Officer for The CORE Institute.  His strategic vision, business acumen and technical expertise have been crucial in clients achieving market-leading enterprise objectives.
            <br /><br />
            
            John holds an MBA from the Carlson School of Management at the University of Minnesota.  With a strong commitment to excellence and a forward-thinking approach, he is poised to lead ConceptVines' Healthcare and Life Sciences division to new heights.</p>`
        },
    ];


    const Ventures = [
        {
            img :venimg1,
            name:"BRIAN FEUER",
            post:"Head of Investments",
            linkedin: "https://www.linkedin.com/in/brianfeuer/",
            bio:`<p>Brian Feuer is the former Global Head of Portfolio Management at McKinsey. In this position, he successfully managed a diverse portfolio of special situation investments while carefully evaluating investment opportunities worldwide. His skills allowed him to negotiate and structure various investments, ranging from early-stage ventures to private equity and even complex public and other liquidity events.

            <br /><br />

            In addition, Brian built and led McKinsey's commercial negotiation team, responsible for strategy development, leading negotiations, managing investor and executive relationships, and overseeing due diligence and technical risk analysis. As the primary negotiator for the firm, Brian also developed and led many of the firm's global negotiation training programs. He specialized in crisis management, coaching McKinsey partners and clients through strategic options, regionally sensitive issues, and risk optimization.
            
            <br /><br />

            Prior to his tenure at McKinsey, Brian was a senior member of the Portfolio Management Group at ORIX USA, a diversified financial services company. There, he was responsible for overseeing the due diligence, financial analysis, and management of the company's North American Mezzanine portfolio.
            
            <br /><br />
            
            Brian has been an active contributor to his community, serving on the board of directors for Tuesdays Children and Cancer 101, both 501C3 organizations. He has lectured at NYU's Stern School of Business and served as a senior advisor to the Stern Incubator.
            
            <br /><br />
                     
            Brian received his MBA from New York University Stern School of Business, after attending the University of Delaware for his undergraduate degree. With his extensive experience and achievements, Brian is a strategic thinker, a seasoned negotiator, and a respected leader who excels in managing complex investments and advising clients on critical business decisions.</p>`
        },
        {
            img :venimg2,
            name:"MITCH DAVIS",
            post:"Operations",
            linkedin: "https://www.linkedin.com/in/mitchell-davis-7a8247/",
            bio:`<p>Mitch Davis is a highly accomplished entrepreneur with a proven track record in the digital entertainment industry.
            <br /><br />

            Davis was the Founder and CEO of Massive Incorporated, which pioneered in-game advertising and built a global network with partners including Xbox, EA, Activision, Sony, Ubisoft and Vivendi Games. The company was acquired by Microsoft for over $200 million in 2006. He served as Managing Director of the Microsoft/Massive business unit where he was responsible for the expansion of the business globally, integration to Xbox and related innovations.

            <br /><br />           
            In 2008, Davis Co-founded Live Gamer, where he built and commercialized an eCommerce platform for digital entertainment that was adopted by Microsoft XBox, Sony Playstation, Electronic Arts, WWE, and Daybreak Games amongst others. Autodesk acquired the core business in 2014. Davis retained the payments side of the business and rebranded it as Emergent Payments in 2015. He focused on emerging markets and hired payments experts to provide payments solutions across LATAM and APAC. Emergent Payments was acquired by Emergent Technology Holdings in 2017 in an all-stock deal, and Davis became CRO responsible for commercial launch of the innovative G-Coin digital token which tokenized physical gold, large client sales, source code corporate sales, and the Demand Generation team.
            
            <br /><br />           
            
            Davis has also held various executive positions, including Senior Vice President of Britannica.com, where he was responsible for building out three major products in 14 languages, and Founder and Director of Klee Consulting, an incubator that co-founded Digital Rights Pty Ltd and Parcelhouse.se. Davis holds a BComm from the University of New South Wales in Sydney, Australia.</p>`
        },
        // {
        //     img :venimg3,
        //     name:"NICOLAS PERKIN",
        //     post:"Chairman & CEO at Perkin Industries",
        //     linkedin: "https://www.linkedin.com/in/nicolasperkin/",
        //     bio:`<p>Nicolas Perkin is an accomplished business leader with expertise in financial technology, mergers and acquisitions, and media. He currently serves as the Chairman and CEO of Perkin Industries, LLC, a company he founded.

        //     <br /><br />           
            
        //     Prior to founding Perkin Industries, Mr. Perkin co-founded Spear Point Buyout Group and The Receivables Exchange, and was part of the team that built Massive Incorporated, which was later sold to Microsoft Corporation. He holds a Master of Science in Finance from the London Business School and a Bachelor of Arts from Tulane University.
            
        //     <br /><br />           
            
        //     In addition to his business accomplishments, Mr. Perkin has made notable contributions to philanthropic organizations. He has served on the Board of Directors for the Youth Rescue Initiative, the Louisiana Technology Council, and The New Orleans Business Alliance Leadership Council, among others. He has recently joined the New Orleans Advisory Board of the Posse Foundation and the Board of Directors of the Louisiana Children's Museum.
            
        //     <br /><br />           
            
        //     Nic's extensive professional experience, impressive educational background, and commitment to philanthropy have made him a respected and influential leader in the business community.</p>`
        // },
        // {
        //     img :venimg4,
        //     name:"BILLY LEVY",
        //     post:"Co-founder, Frequency Advisors",
        //     linkedin: "/",
        //     bio:`<p>William “Billy” Levy is an accomplished entrepreneur with almost 20 years of experience in creating successful companies. He has raised over $200M for his businesses and created over $1.4B in shareholder value. Billy is skilled at identifying consumer trends and developing innovative solutions that drive business growth. He has held executive positions in several leading companies in the Gaming and Legal Cannabis industries, including co-founding Virgin Gaming with Sir Richard Branson, and SLANG Worldwide with Peter Miller.

        //     <br /><br />           
            
        //     Billy has also played a crucial role in the creation and growth of various other companies, such as Nerd Alert, Virgin Mega, and GG Group. He currently works with Frequency Advisors, a boutique advisory firm, and serves as Vice Chairman & Corporate Development for Rivalry, an online sportsbook at the intersection of online betting, esports, and gaming-oriented media/entertainment. Billy's success as an entrepreneur is attributed to his visionary leadership, deep knowledge of consumer behavior, and market trends.</p>`
        // },
        // {
        //     img :venimg5,
        //     name:"DREW EVENSON",
        //     post:"Partner, Frequency Advisors",
        //     linkedin: "https://www.linkedin.com/in/drewevenson",
        //     bio:``
        // },
        // {
        //     img :venimg6,
        //     name:"SUMMER WATSON",
        //     post:"Partner, Frequency Advisors",
        //     linkedin: "https://www.linkedin.com/in/summerwatson?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
        //     bio:``
        // },
        // {
        //     img :venimg7,
        //     name:"VIKAL KAPOOR",
        //     post:"Founding partner of SevenTrainVentures",
        //     linkedin: "https://www.linkedin.com/in/vikal/",
        //     bio:`<p>Vikal is a veteran of Fintech, Commerce, and Investments, with over 20 years of experience as a futurist, serial entrepreneur, and investor. He has worked in Wall Street, private equity, and venture capital, gaining a broad network of professionals.
            
        //     <br /><br />           
            
        //     As a Founding Partner of SevenTrainVentures, Mr. Kapoor executes strategic growth and investment for top decile of venture-backed companies from formation to pre-IPO including M&A, sales acceleration, and game-changing partnerships. His clients include private and publicly traded companies in Fintech, PropTech, eCommerce, AI, Blockchain, Cyber, Data & Analytics, PropTech, and MedTech.
            
        //     <br /><br />           
            
        //     Mr. Kapoor's past work includes leading the successful growth, turnarounds, and exits of VC-, PE- and LP-backed financial markets, technology, services, and marketing cos. He is also co-founder of Stateset, PolrLake, and Dapps, and contributes to larger causes such as working with top global executives on the Digital Transformation Committee for the World Economic Forum.'
            
        //     <br /><br />           
                       
        //     Mr. Kapoor completed an MBA from ESADE Business School (Forbes Top 5 Non-US MBA), where he was elected Co-Class President. He is passionate about freeing humankind from societal conditioning, elevating our consciousness, and enjoying sports and playing with his children.</p>`
        // },
        {
            img :venimg8,
            name:"ELIZABETH FURBER",
            post:"Sales & Business Development",
            linkedin: "https://www.linkedin.com/in/elizabeth-goin-furber-54587313/",
            bio:`<p>Elizabeth Furber is a finance professional with over 30 years of experience in the industry. As the founder of HSA, she has become a leading figure in investment management and is widely recognized as an expert in her field. Ms. Furber is a registered representative of First Dominion Capital Corp. with Series 7 and Series 63 licenses, which enables her to provide a broad range of financial services to her clients.

            <br /><br />           
            
            Prior to starting HSA, Ms. Furber served as a Vice President in the Investment Services Group at Donaldson, Lufkin & Jenrette. She was also a Vice President at Kidder, Peabody & Co. in Private Wealth/Middle Market Institutional Sales, Derivatives Trading, IT, and Internal Audit. Her experience also includes working as an associate in Mortgage-Backed Securities Research at Goldman, Sachs.
            
            <br /><br />           
            
            In addition, Ms. Furber is a co-founder of the Boston Alternative Investments Networking Group and a past Director of the NY Financial Analysts and Money Managers Society. She graduated summa cum laude with a B.S. in Computer Science from Manhattan College and received an MBA in Finance from New York University's Stern School of Business.
            
            <br /><br />           
            
            Ms. Furber is an active Board Director at Wellspring House, Gloucester MA, and Co-Head of the Finance Committee, where she is passionate about helping others achieve financial success.</p>`
        }
    ];

    const Advisorsy = [
        {
            img :advimg1,
            name:"RICHARD FULD",
            post:"Strategic Investor, CEO of Matrix and CEO of Lehman",
            linkedin: "/",
            bio:`<p>Dick Fuld is an Investor/Strategic Advisor at ConceptVines and the Founder and Chairman of Matrix Investment Holdings, a strategic advisory and investment firm focused on FinTech innovation and cross-industry sustainability. Previously, he was the Chairman and CEO of Lehman Brothers, one of the world's top five investment banks.

            <br /><br />           
            
            Dick is a well-connected and experienced leader who has served on the boards of organizations such as the New York Stock Exchange, the Partnership for New York City, and the Federal Reserve Bank of New York. He also provides advisory council and mentorship to portfolio companies and supports the evolution of capital markets, including the tokenization of private assets.
            
            <br /><br />           
            
            With a broad executive network and a passion for progressive technologies, Dick helps ConceptVines bridge the gap between startups and enterprises. He and his wife, Kathy, are active philanthropists, supporting organizations such as the Museum of Modern Art, and he is a Board of Trustees Member at New York Presbyterian Hospital.</p>`
        },
        {
            img :advimg2,
            name:"ERIC MARTINEZ",
            post:"Insurance/Manufacturing Automation CEO Modjoul, Former Chief Claims Officer AIG",
            linkedin: "https://www.linkedin.com/in/rericmartinez/",
            bio:``
        },
        {
            img :advimg3,
            name:"GREG BAXTER",
            post:"Technology/Financial Services Chief Transformation Officer HP, Former Chief Digital Officer MetLife, Former Head Digital Citi",
            linkedin: "https://www.linkedin.com/in/greg-baxter/",
            bio:``
        },
        {
            img :advimg4,
            name:"ALYSE DAGHELIAN",
            post:"Technology Sales - VP Global Sales Service Now. Former VP Global Sales IBM Cloud, Data, AI.",
            linkedin: "https://www.linkedin.com/in/alyse-daghelian-3579157/",
            bio:``
        },
        {
            img :advimg5,
            name:"KURT CAVANO",
            post:"Global Supply Chain/Venture Investments CEO Nimbly, Former CEO GT Nexus / TradeCard",
            linkedin: "https://www.linkedin.com/in/kurt-cavano-721ba71/",
            bio:``
        },
        {
            img :advimg6,
            name:"ROBIN FARMANFARMAIAN",
            post:"Healthcare/Marketing/Venture Investments Healthcare Innovator & Entrepreneur, Sales & Marketing Expert, Private Equity",
            linkedin: "https://www.linkedin.com/in/robinfarmanfarmaian/",
            bio:``
        },
        {
            img :advimg7,
            name:"CHRISTOPHER COLBERT",
            post:"Strategy / Marketing. Advisor and Innovation Strategist. Former Managing Director of Harvard Innovation Labs. CEO Holland Mark Marketing Agency",
            linkedin: "https://www.linkedin.com/in/theglobalist/",
            bio:``
        },
        {
            img :advimg8,
            name:"NADEEM SHAIKH",
            post:"FinTech/Venture Investments Founder/CEO NeemExponential, Founder/CEO Anthemis, Head International First Data,80+ FinTech Investments",
            linkedin: "https://www.linkedin.com/in/nrshaikh/",
            bio:``
        },
        {
            img :advimg9,
            name:"VASAN SRINIVASAN",
            post:"Leadership, Culture & Sales Advisor. EVP Virtusa & Cap Gemini, President Birlasoft, SVP FIS.",
            linkedin: "https://www.linkedin.com/in/vasan-srinivasan-6610885/",
            bio:``
        },
        {
            img :advimg10,
            name:"DAN NALAWADE",
            post:"Head Matrix PCG Financing Solutions, CEO & President GE Capital APAC,Board Member & Advisor",
            linkedin: "https://www.linkedin.com/in/dan-nalawade-73761b11/",
            bio:`<p>Dan is a Board member and advisor to Turning Rock Partners, helping to make investments in new ventures, optimizing portfolio companies, and finding optimum exit strategies. At GE, he served as President and CEO of Asia & Pacific for GE Transportation Finance, where he developed a mid-market finance strategy across the supply chain and identified new mid-market finance and leasing opportunities.

            <br /><br />           
                        
            As CEO of GE Equipment Services Asia, Dan established new business ventures, identified potential alliances, and developed a GE Supply Chain Finance Strategy for Asia. He also led GE Capital’s supplier finance group in Eastern Europe, developing purchase order finance and loan against share programs for emerging CEE suppliers.
            
            <br /><br />           
            
            In addition to his business accomplishments, Dan holds multiple degrees, including LLB, MBA, MA (Theology), MA (Economics), and B.Com. He is also a Certified Public Account (C.P.A.), Six Sigma Master Black Belt, and holds a U.S. patent for GE.</p>`,
        }
    ];

  return (
    <section className='wwerinnovators-section' id='ourteam'>
        <div className='container heading'>
         
            <div className="bl-green pt-0 pb-3">
                <h2><span>Our </span> team</h2>
                <div className="text-box">
                    <p>Our company was formed by bringing together highly experienced and specialized expertise from several areas: Private Capital, Strategic Consulting and Product Engineering. Our founding team and core leadership are recognized transformation leaders with deep business and technology backgrounds. Our CEO and Founder has helped build and grow two Billion Dollar+ Technology Services organizations.</p>
                </div>
            </div>
                <p>Our combined leadership team has extensive technology experience and domain depth across all industries including Banking & Capital Markets, Insurance,  Healthcare, Life Sciences, Manufacturing and Logistics.</p>
                <p>​On our venture’s side, a General Partner in our company was the CEO and Chairman of one of Wall Street’s top Investment Banks and our supporting investment team have extensive Venture Capital experience in the sourcing and management of great technology firms.</p>
                <p>​Our advisory board and broader network consists of world renowned, progressive business and technology leaders who believe in the ConceptVines cause and business model and are all active contributors to our platform.</p>
                <p>​Most importantly the core of our company is our global Engineering talent. Our focus is to provide expert engineering services around emerging technologies; including Blockchain (DeFi), AI, Generative AI, IOT, Robitics, NextGen Cloud, Extended Reality, and others.</p>
                <p>Our charter is to be seen as a unique innovation partner and builder of new business models for our clients.</p>
        </div>
        <div className='container'>

          <div className='mb-5'>
            <div className='heading-box'>
                LEADERSHIP TEAM
            </div>

            <div className='row team g-5 consulting-team'>

                {
                    consulting.map((member, index)=>{
                        return(
                            <div key={index} className={(index === 0 || index === 3 || index === 6 || index === 9) ? 'member col-md-3 offset-md-1' : 'member col-md-3'}>
                                <div className='member-wrapper'>
                                    <div>
                                    <div className='img-box'>
                                        <img src={member.img} alt='' />
                                    </div>
                                    <div className='text-box'>
                                        <h3>{member.name}</h3>
                                        <p>{member.post}</p>
                                    </div>  
                                    </div>
                                    <div className='additional'>
                                        {(member.bio !== "" ) ? <MemberBio name={member.name} post={member.post} bio={member.bio} img={member.img} /> : "" }
                                        {(member.linkedin !== "/" ) ? <a href={member.linkedin} target='_blank'  rel="noreferrer"><span><img className='desktop-inline' src={profile} alt='' /><img className='mobile-inline  tablet-inline' src={profilemb} alt='' /></span></a>  : "" }
                                       {/* 
                                        {(member.bio !== "" ) ? (member.linkedin !==  "/" ) ? <span className='showbio'>Additional Bio</span> : <span className='showbio'>Additional Bio</span>   :  (member.linkedin !==  "/" ) ? <a className='viewlinkedin' href={member.linkedin}  rel="noreferrer" target='_blank'>View Linkedin Profile</a> : "" }
                                        */}
                                        {(member.bio !== "" ) ? (member.linkedin !==  "/" ) ? <span className='showbio'>Additional Bio</span> : <span className='showbio d-block cheight' >Additional Bio</span>   :  (member.linkedin !==  "/" ) ? <a className='viewlinkedin' href={member.linkedin}  rel="noreferrer" target='_blank'>Additional Bio</a> : "" }
                                    
                                    </div>  

                                </div>
                            </div>
        
                        )
                    })
                }
 
                </div>
          </div>
          
          <div className='mb-5'>
            <div className='heading-box'>
                VENTURE TEAM
            </div>

            <div className='row team g-5 venture-team'>

                {
                    Ventures.map((member,index)=>{
                        return(
                            // <div key={index} className={(index === 6 || index === 9) ? 'member col-md-3 offset-1' : 'member col-md-3'}>
                            <div key={index} className={(index === 0) ? 'member col-md-3' : 'member col-md-3'}>
                                <div className='member-wrapper'>
                                <div>
                                <div className='img-box'>
                                    <img src={member.img} alt='' />
                                </div>
                                <div className='text-box'>
                                    <h3>{member.name}</h3>
                                    <p>{member.post}</p>
                                </div>  
                                </div>
                                <div className='additional'>
                                    {/* <MemberBio name={member.name} post={member.post} bio={member.bio} img={member.img} />
                                    {(member.linkedin !== "" ) ? <span><a href={member.linkedin} target='_blank'  rel="noreferrer"><span><img className='desktop-inline' src={profile} alt='' /><img className='mobile-inline' src={profilemb} alt='' /></span></a></span>  : "" }
                                    {(member.bio !== "" ) ? <span className='showbio'>Additional Bio</span> : <a className='viewlinkedin' href={member.linkedin}  rel="noreferrer" target='_blank'>View Linkedin Profile</a> } */}

                                    {(member.bio !== "" ) ? <MemberBio name={member.name} post={member.post} bio={member.bio} img={member.img} /> : "" }
                                    {(member.linkedin !== "/" ) ? <a href={member.linkedin} target='_blank'  rel="noreferrer"><span><img className='desktop-inline' src={profile} alt='' /><img className='mobile-inline  tablet-inline' src={profilemb} alt='' /></span></a>  : "" }
                                    {/* {(member.bio !== "" ) ? <span className='showbio'>Additional Bio</span> :  (member.linkedin !== "/" ) ? <a className='viewlinkedin' href={member.linkedin}  rel="noreferrer" target='_blank'>View Linkedin Profile</a> : "" } */}
                                    {(member.bio !== "" ) ? (member.linkedin !==  "/" ) ? <span className='showbio'>Additional Bio</span> : <span className='showbio d-block cheight'>Additional Bio</span>   :  (member.linkedin !==  "/" ) ? <a className='viewlinkedin' href={member.linkedin}  rel="noreferrer" target='_blank'>Additional Bio</a> : "" }


                                    {/* {(member.linkedin !== "" ) ? <span><img src={profile} alt='' /></span>  : "" }
                                    {(member.bio !== "" ) ? <span><NavLink>Additional Bio</NavLink></span> : "" } */}
                                </div>  
                                </div>
                            </div>
        
                        )
                    })
                }
                </div>
          </div>
          
          <div className='mb-5'>
            <div className='heading-box'>
                ADVISORY TEAM
            </div>

            <div className='row team g-5 advisory-team'>

                {
                    Advisorsy.map((member,index)=>{
                        return(
                            <div key={index} className={(index === 0 || index === 3 || index === 6 || index === 9) ? 'member col-md-3 offset-md-1' : 'member col-md-3'}>
                            {/* <div key={index} className={(index === 6 || index === 8) ? 'member col-md-3 offset-1' : 'member col-md-3'}> */}
                              <div className='member-wrapper'>
                                <div>
                                <div className='img-box'>
                                    <img src={member.img} alt='' />
                                </div>
                                <div className='text-box'>
                                    <h3>{member.name}</h3>
                                    <p>{member.post}</p>
                                </div>  
                                </div>
                                <div className='additional'>
                                    
                          
                                        {(member.bio !== "" ) ? <MemberBio name={member.name} post={member.post} bio={member.bio} img={member.img} /> : "" }
                                        {(member.linkedin !== "/" ) ? <a href={member.linkedin} target='_blank'  rel="noreferrer"><span><img className='desktop-inline' src={profile} alt='' /><img className='mobile-inline  tablet-inline' src={profilemb} alt='' /></span></a>  : "" }
                                        {(member.bio !== "" ) ? (member.linkedin !==  "/" ) ? <span className='showbio'>Additional Bio</span> : <span className='showbio d-block cheight'>Additional Bio</span>   :  (member.linkedin !==  "/" ) ? <a className='viewlinkedin' href={member.linkedin}  rel="noreferrer" target='_blank'>Additional Bio</a> : "" }
                                         
                                </div>  
                               </div>     
                            </div>
        
                        )
                    })
                }
                </div>
          </div>
          
        </div>
    </section>  
  
  )
}

export default WwerInnovators
