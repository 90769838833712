import React from 'react'
import Menu from '../components/Menu'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import SingleBody from '../components/SingleBody'

const Insight = () => {
  return (
    <>
    {/* <Helmet>
      <title></title>
      <meta property="og:title" content="" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet> */}
   
    <div>
        <Menu />
        <Header2 class="terms"/>
        <SingleBody />
        <Footer />      
    </div>

    </>
  )
}

export default Insight
